import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
const firebaseApp = initializeApp({
  type: "service_account",
  project_id: "infinity-block-website",
  private_key_id: "4343f97c2f8b16b063dc31f084edbd674697926c",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDImCGWTnB+Xljx\no4OUk7C56ggUGoGDqL/bC3dS43EIxd081hQSc3k1kB1hhhMxxLEKIsIGaXfzPC3s\nTWVApo7kRs9OmBoOKx9hKoanAajfRqB1h8K2B6TnI+S50z+Y2EAuBHr9rz48VRhB\n5xxEyhP14ZvVqwDDBXtPbgxugCRAu2ctKKdUbd1p3D9+J8WwzRxBd2TAL5k3sNH1\nXtSDaAOXL1Y2mm1lLyMwkxKkBAihoc2gYloZKuLfGizPOAIf6sYU6IC0gTWw737I\n7N33Wd+eBhaRAr0GkEjoRnStBPEEjxSZUwBVkT5226MjBL2xqJfp5TMW3f9nlKuV\nmnC3H0W7AgMBAAECggEAH99VnYl6xAbKBiGk3hOLcIw4MA2NckslVNt2YF66zpng\necf1f4Bb4YAJExOtHWrIdeOo8EDg1MPv82HRdUYht9wXw2qqioFykSCjLYpiIpFD\nx05EyLc1OJ685dkkJpfcYWjH1rgktjcPzXo4b9EAqUBpijfgvUaz3Z6O7h3Wj3bk\nhOai6oJoBWnKMOlhZWCXdgzlYve9YlOYh28zHIB5wJmEFgPyFScl2/Wg/LCJHWo8\nv069HNyg/lOhl9lw8ru/qeqHU3ANXAJcXNNd53WKWDh3sFBqTF6z8JNoZCTmC6KV\nQ+Dq3zBQlYCJXegDKxZTFuYh+c81ZMQwdXDqTuKqzQKBgQD1LIWzhtRzpsHYtSGn\nxKfaFHqcgLT2NyOQ/TC1RjrV6FzNZh323kgxOgxlc1qSYGr3iGnXi825yZgOf3Iq\nz7nDHsdfp3OmEcPw4GJ/wGP40Iqv6tBU7NZw6z4Bvt7mrqNaPL7reMMi1HeNv4dq\nZ9/S18AgtvLZQ+V1A9hWbmJ05QKBgQDRc6y8v0d6JdrERSgAuOh8b9sk8TIQQ+i3\nApHQbd4Y0Cf5/+NRKRp5Ne3eygjPAEQnI34vS43ZXMmKsA1YY3w5vUPwRcWYeHgW\nmbe8mh9eJQbwemCC/AxyiNwXcEx6MGf6sguUVVl1BerYa8s28sS4qq46/k9201dN\ncRrvlQLGHwKBgDHxPdMSNhjQ8U/Zpd9s+dw8q7VRqos1NDOGv/NNORL14GGe2Z++\nK+qLLpdD5kFHDsMZsKXNvryHyke5wmHiKq8KiAZ+L9q2Z7mbm7T2VecLZBTtuj5y\neERMRzdH0FQhYvkwwpto4CcqLKhdSZJtUnS6qlJ/sfx8DjPF8r24gqnZAoGAax8M\nI2R4vAIMRwFPmj73uFzE6bnp7uOsa50G0mC+jFY5e+Thb6rj/UYVqDIQGz55nMLM\nUIgFvjt0YFf2G1SWPjmMO6CTlI1Ybcakb/MrQ80QJYGRRqx9+T4BwoMOuhdztoXE\n4lJcdDtYhuNqhWnYLtIsVcOnBJDGP7cvHYVKa+UCgYBGlv9Zq/Oi0B2G6DN+bWYd\nBV7sGhO5dL673KBHJ+NhXNP9Zp602zs8u/B32xqLoA1Ggvft+/8we8pE5RFZp2P6\nW+obAeUMh3HnENUdL9DCdJbI4AN79wmGmEM3kSK1YWx5VO+PcMiFwgRrCt8tDQUj\nIaSW2izi3QbbO8QpeGvLYQ==\n-----END PRIVATE KEY-----\n",
  client_email:
    "firebase-adminsdk-l5mkb@infinity-block-website.iam.gserviceaccount.com",
  client_id: "112269848680943450275",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  storageBucket: "gs://infinity-block-website.appspot.com/",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-l5mkb%40infinity-block-website.iam.gserviceaccount.com",
  universe_domain: "googleapis.com",
  apiKey: "AIzaSyBXhnAa_ohCQDI6B8s9afZ1b0EjnojuauY",
  authDomain: "infinity-block-website.firebaseapp.com",
  projectId: "infinity-block-website",
  messagingSenderId: "833312831682",
  appId: "1:833312831682:web:ef07693ceb183e4e65635b",
  measurementId: "G-KQ246VPSRN",
});

// const storage = firebase.storage();
const analytics = getAnalytics(firebaseApp);
// const storage = getStorage(app);
export { firebaseApp, analytics };
