import { Box, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import LoadingContext from "../../context/loadingContext";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
function WebSlider({ isMobile }) {
  const theme = useTheme();

  const [activeIndex, setActiveIndex] = useState(0);

  if (isMobile) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "90%",
          position: "relative",
        }}
      >
        <AutoPlaySwipeableViews
          index={activeIndex}
          // autoplay={false}
          // interval={40000}
          onChangeIndex={index => {
            setActiveIndex(index);
          }}
          containerStyle={{ width: "100%", height: "100%" }}
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
          slideStyle={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            // backgroundColor: "#000",
            justifyContent: "center",
          }}
          enableMouseEvents
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundImage: "url(/images/bannerBG1.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              position: "relative",

              display: "flex",
              justifyContent: "space-around",
              // gap: 20,
            }}
          >
            <img
              alt="Infinity Block"
              data-aos="zoom-in"
              src={"/images/infinityLogoBlack.png"}
              style={{
                width: 40,
                height: 40,
                top: 10,
                left: 10,
                position: "absolute",
              }}
            />
            <Box
              sx={{
                width: "100%",
                height: "100%",
                p: "2rem 0rem",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                data-aos="flip-up"
                alt="sturdy construction"
                src={"/images/bannerText1.png"}
                style={{ width: "50%" }}
              />
              <img
                data-aos="zoom-in"
                alt="infinity block buiding"
                src={"/images/Building.png"}
                style={{ width: "90%" }}
              />
            </Box>
            {/* <Box
              sx={{
                // width: "60%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
           
            </Box> */}
          </Box>
          {/* <img
          alt="header-banner1"
          style={{ width: "100%" }}
          src="/images/banner1.jpeg"
        /> */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundImage: "url(/images/bannerBG2.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              display: "flex",
              position: "relative",

              justifyContent: "space-around",
              // gap: 20,
            }}
          >
            <img
              alt="Infinity Block"
              data-aos="zoom-in"
              src={"/images/infinityLogoBlack.png"}
              style={{
                width: 40,
                height: 40,
                top: 10,
                left: 10,
                position: "absolute",
              }}
            />
            <Box
              sx={{
                width: "100%",
                height: "100%",
                p: "2rem 0rem",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                data-aos="flip-up"
                alt="sturdy construction"
                src={"/images/bannerText2.png"}
                style={{ width: "50%" }}
              />
              <img
                data-aos="zoom-in"
                alt="infinity block buiding"
                src={"/images/golo.png"}
                style={{ width: "90%" }}
              />
            </Box>
            {/* <Box
              sx={{
                // width: "60%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
           
            </Box> */}
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundImage: "url(/images/bannerBG3.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              display: "flex",
              position: "relative",

              justifyContent: "space-around",
              // gap: 20,
            }}
          >
            <img
              alt="Infinity Block"
              data-aos="zoom-in"
              src={"/images/infinityLogoBlack.png"}
              style={{
                width: 40,
                height: 40,
                top: 10,
                left: 10,
                position: "absolute",
              }}
            />
            <Box
              sx={{
                width: "100%",
                height: "100%",
                p: "2rem 0rem",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                data-aos="flip-up"
                alt="sturdy construction"
                src={"/images/banner3Obj.png"}
                style={{ width: "80%" }}
              />
              <img
                data-aos="zoom-in"
                alt="infinity block buiding"
                src={"/images/bannerText3.png"}
                style={{ width: "50%" }}
              />
            </Box>
            {/* <Box
              sx={{
                // width: "60%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
           
            </Box> */}
          </Box>
        </AutoPlaySwipeableViews>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            width: "100%",
            display: "flex",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {[...Array(3)].map((item, index) => {
            return (
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  background: index == activeIndex ? "#000" : "grey",
                }}
              ></Box>
            );
          })}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 165px)",
          position: "relative",
        }}
      >
        <AutoPlaySwipeableViews
          index={activeIndex}
          // autoplay={false}
          // interval={40000}
          onChangeIndex={index => {
            setActiveIndex(index);
          }}
          containerStyle={{ width: "100%", height: "100%" }}
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
          slideStyle={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            // backgroundColor: "#000",
            justifyContent: "center",
          }}
          enableMouseEvents
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundImage: "url(/images/bannerBG1.png)",
              backgroundPosition: "center",
              position: "relative",

              backgroundSize: "cover",
              display: "flex",
              justifyContent: "space-around",
              // gap: 20,
            }}
          >
            <img
              alt="Infinity Block"
              data-aos="zoom-in"
              src={"/images/infinityLogoBlack.png"}
              style={{
                width: 100,
                height: 100,
                top: 25,
                left: 25,
                position: "absolute",
              }}
            />
            <Box
              sx={{
                // width: "40%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                data-aos="flip-up"
                alt="sturdy construction"
                src={"/images/bannerText1.png"}
                style={{ width: 430, maxWidth: 500 }}
              />
            </Box>
            <Box
              sx={{
                // width: "60%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                data-aos="zoom-in"
                alt="infinity block buiding"
                src={"/images/Building.png"}
                style={{ height: "90%" }}
              />
            </Box>
          </Box>
          {/* <img
          alt="header-banner1"
          style={{ width: "100%" }}
          src="/images/banner1.jpeg"
        /> */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundImage: "url(/images/bannerBG2.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              display: "flex",
              position: "relative",
              justifyContent: "space-around",
              // gap: 20,
            }}
          >
            <img
              alt="Infinity Block"
              data-aos="zoom-in"
              src={"/images/infinityLogoBlack.png"}
              style={{
                width: 100,
                height: 100,
                right: 25,
                top: 25,
                position: "absolute",
              }}
            />

            <Box
              sx={{
                // width: "60%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                data-aos="zoom-in"
                alt="infinity block buiding"
                src={"/images/golo.png"}
                style={{ height: "90%" }}
              />
            </Box>
            <Box
              sx={{
                // width: "40%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                data-aos="flip-up"
                alt="sturdy construction"
                src={"/images/bannerText2.png"}
                style={{ width: 500, maxWidth: 500 }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundImage: "url(/images/bannerBG3.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
              display: "flex",
              position: "relative",
              justifyContent: "space-around",
              // gap: 20,
            }}
          >
            <img
              alt="Infinity Block"
              data-aos="zoom-in"
              src={"/images/infinityLogoBlack.png"}
              style={{
                width: 100,
                height: 100,
                right: 25,
                top: 25,
                position: "absolute",
              }}
            />

            <Box
              sx={{
                // width: "60%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                data-aos="zoom-in"
                alt="infinity block buiding"
                src={"/images/banner3Obj.png"}
                style={{ height: "90%" }}
              />
            </Box>
            <Box
              sx={{
                // width: "40%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                data-aos="flip-up"
                alt="sturdy construction"
                src={"/images/bannerText3.png"}
                style={{ height: "80%" }}
              />
            </Box>
          </Box>
        </AutoPlaySwipeableViews>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            width: "100%",
            display: "flex",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {[...Array(3)].map((item, index) => {
            return (
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  background: index == activeIndex ? "#000" : "grey",
                }}
              ></Box>
            );
          })}
        </Box>
      </Box>
    );
  }
}

export default WebSlider;
