export const NAVBAR_DATA = [
  { lable: "Home", path: "/" },
  { lable: "About us", path: "/about" },
  { lable: "AAC", path: "/ourProduct" },
  { lable: "Benefits", path: "/aac-benefits" },
  { lable: "Guidelines", path: "/aac-guidelines" },
  { lable: "Comparision", path: "/aac-technical" },
  { lable: "Gallery", path: "/infinity-gallary" },
  { lable: "Contact Us", path: "/contact-us" },
];

export const ABOUT_US_CONTENT = [
  "Infinity Blocks Pvt. Ltd. is dedicated to creating a sustainable society where the environment and future can coexist in harmony. Contemporary construction practices encompass various essential aspects. Alongside aesthetics and strength, products are evaluated based on the following attributes:",
  ["Affordability ", "Safety", "Eco-Friendliness", "Workability"],
  "These many attributes propelled us to present Infinity Autoclaved Aerated Concrete Blocks � An eco-friendly, cost-effective and safe brick solution which preserves the harmony of the nature and save your project form heavy construction cost. Truly it proves to be a solid option to traditional clay brick.",
  "Based at Ahmedabad, Gujarat, we at Infinity Blocks Pvt. Ltd. Are poised to revolutionize and transform the conventional construction practices with innovative and effective Infinity Autoclaved aerated concrete (AAC) Blocks.",
];

export const VISION =
  "Our vision is to bring our customers into 21st Century, through innovation and modern technology and leave a sustainable world for the future generations";

export const MISSION =
  "We aim at becoming a leading manufacturer of AAC Blocks in Gujarat, and stay committed to offering a complete system backed up by skilled technical support and excellent customer service. We believe in delivering high quality and cost effective products which adds value to our customers. We act with integrity and respect. We are continuously driven towards the improvement of our environmental performance and thrive for a greener and more sustainable planet.";

export const BENEFITS_TEXT = [
  "The advantages of AAC blocks are diverse and numerous, particularly when considering India's specific conditions. ",
  "Here are some distinctive advantages from India's perspective",
];

export const DESC_OF_AAC = [
  "The technical performance and quality standard of INFINITY’s block meet the requirements of the national autoclaved aerated concrete block GB11968-2006 standard. It is suitable for civil and industrial buildings in areas with seismic fortification intensity less than 7 degrees, which are made of non bearing wall materials by dry thin layer masonry technology; it is suitable for heat preservation and energy saving buildings, which are widely used in residential buildings, office buildings, hospitals, hotels, schools and other civil, public and industrial buildings.",
];

export const BENEFITS_OF_AAC = [
  {
    img: "/images/icon_2.png",
    title: "Fire Resistant",
    description:
      "Depending upon the thickness of the Autoclaved Aerated Concrete (AAC) Blocks, they offer fire resistance from 2 hours up to 6 hours. These blocks are highly suitable for the areas where fire safety is of great priority.",
    shortDescription:
      "Depending upon the thickness of the AAC Blocks, they offer fire resistance from 2 hours up to 6 hours. ",
  },
  {
    img: "/images/icon_3.png",
    title: "Pest Resistant",
    description:
      "Autoclaved Aerated Concrete (AAC) Block consist of the inorganic material in its constitution that helps preventing/avoiding termites, damages or losses.",
    shortDescription:
      "AAC Block consist of the inorganic material in its constitution that helps preventing/avoiding termites, damages or losses.",
  },

  {
    img: "/images/icon_9.png",
    title: "Cost Saving",
    description:
      "AAC block weighs almost around 80% less as compared to the conventional red brick ultimately resulting into great reduction of deadweight. Further, the reduced deadweight results into reduction of the use of cement and steel which helps great in cost savings.",
  },
  {
    img: "/images/versatile.png",
    title: "Versatile",
    description:
      "AAC Blocks have an attractive appearance and is readily adaptable to any style of architecture. Almost any design can be achieved with AAC.",
  },
  {
    img: "/images/icon_4.png",
    title: "Sound Proof",
    description:
      "The porous structure of the AAC blocks results into enhanced sound absorption. The Sound Transmission Class (STC) rating of the AAC blocks up to 45 db. Thus, AAC blocks have been the most ideal material for the construction of walls in auditorium, hotels, hospitals, studios, etc.",
    shortDescription:
      "The porous structure of the AAC blocks results into enhanced sound absorption.",
  },

  {
    img: "/images/icon_5.png",
    title: "Earthquake Resistant",
    description:
      "The light weight property of the AAC blocks results into higher steadiness of the AAC blocks in the structure of the buildings. As the impact of the earthquake is directly proportional to the weight of the building, the building constructed using AAC blocks are more reliable and safer.",
    shortDescription:
      "The light weight property of the AAC blocks results into higher steadiness of the AAC blocks in the structure of the buildings. ",
  },
  {
    img: "/images/icon_7.png",
    title: "Faster Construction",
    description:
      "As the AAC block is very easy to handle, manipulate and use ordinary tools for cutting the wood such as the drill, band saws, etc. could be easily used to cut and align the AAC. Moreover, the AAC blocks come with larger sizes and fewer joints. This ultimately results in faster construction work as the installation time is significantly reduced due to fewer amounts of blocks and the masonry amount involved is also lowered resulting into reduced time-to-finish.",
    shortDescription:
      "As the AAC block is very easy to handle, manipulate and use ordinary tools for cutting the wood such as the drill, band saws, etc.",
  },

  {
    img: "/images/icon_8.png",
    title: "Long Lasting",
    description:
      "AAC blocks are highly superior in terms of the strength. Higher level of strength of these blocks gives higher stability to the structure of the building. AAC is manufactured from non-biodegradable materials, which neither rot nor attract mould, keeping interiors clean and durable.",
  },

  {
    img: "/images/non_toxic.png",
    title: "Non-toxic",
    description:
      "Autoclaved Aerated Concrete products do not contain any toxic gas substances. The product does not harbor or encourage vermin.",
  },
];

export const GUIDELINES_OF_AAC = [
  {
    img: "/images/gud_img1.jpg",
    title: "Stacking",
    description:
      "On dry and even surface to avoid Damage & contact with moisture",
  },
  {
    img: "/images/gud_img8.jpg",
    title: "Lintel support",
    description: "Lintel support on full block",
  },
  {
    img: "/images/gud_img5.jpg",
    title: "Coping Beam",
    description: "Coping beam with 2 nos 8mm reinforcement After 1.2mts height",
  },
  {
    img: "/images/gud_img3.jpg",
    title: "Wetting of Block Before Application",
    description: "Dip in water & Lift immediately",
  },
  {
    img: "/images/gud_img2.jpg",
    title: "Mortar For Masonry",
    description: "AAC mortar mix or cement: sand(1:6)",
  },
  {
    img: "/images/gud_img6.jpg",
    title: "Curing of Masonry Wall",
    description: "Curing required only for mortar joint",
  },
  {
    img: "/images/gud_img7.jpg",
    title: "Cutting of Block",
    description: "Use tool like hacksaw or rotary cutter",
  },
  {
    img: "/images/gud_img9.jpg",
    title: "Electric & Sanitary Chases",
    description: "Chases to be grooved before plaster of wall",
  },
  {
    img: "/images/gud_img4.jpg",
    title: "Mortar Thickness",
    description: "Keep it limited to 10 to 12mm",
  },
  {
    img: "/images/gud_img10.jpg",
    title: "Plaster",
    description: "Minimum external plaster thickness",
  },
];

export const WHAT_IS_AAC = [
  "Autoclaved Aerated Concrete is a Lightweight, Load-bearing, High-insulating, Durable building product, which is produced in a wide range of sizes and strengths. AAC Blocks is lightweight and compare to the red bricks AAC blocks are three times lighter.",
];

export const ORIGIN_OF_AAC = [
  "The Autoclaved Aerated Concrete (AAC) material was developed in 1924 in Sweden. It has become one of the most used building materials in Europe and is rapidly growing in many other countries around the world.",
  "AAC is produced from the common materials lime, sand, cement and water, and a small amount of rising agent. After mixing and molding, it is then autoclaved under heat and pressure to create its unique properties. AAC has excellent thermal insulation and acoustic absorption properties. AAC is fire and pest resistant, and is economically and environmentally superior to the more traditional structural building materials such as concrete, wood, brick and stone.",
  "At the point when AAC is blended and cast in structures, a few compound responses occur that give AAC its light weight (20% of the heaviness of cement) and warm properties. Aluminum powder responds with calcium hydroxide and water to frame hydrogen. The hydrogen gas froths and copies the volume of the crude blend making gas rises to 3mm (⅛ inch) in distance across. Toward the end of the frothing procedure, the hydrogen escapes into the air and is supplanted via air.",
  "AAC Blocks is a unique and excellent type of building material due to its super heat, fire and sound resistance, AAC blocks are lightweight and offers ultimate workability, flexibility and durability. Its main ingredients include sand, water, quicklime, cement and gypsum. The chemical reaction due to the aluminum paste provides AAC its distinct porous structure, lightness, and insulation properties, completely different compared to other lightweight concrete materials.",
  "When the forms are removed from the material, it is solid but still soft. It is then cut into either blocks or panels, and placed in an autoclave chamber for 12 hours. During this steam pressure hardening process, when the temperature reaches 190° Celsius (374° Fahrenheit) and the pressure reaches 8 to 12 bars, quartz sand reacts with calcium hydroxide to form calcium silicate hydrate, which gives AAC its high strength and other unique properties. Because of the relatively low temperature used AAC blocks are not considered fired brick but a lightweight concrete masonry unit. After the autoclaving process, the material is ready for immediate use on the construction site. Depending on its density, up to 80% of the volume of an AAC block is air. AAC's low density also accounts for its low structural compression strength. It can carry loads of up to 8 MPa (1,160 PSI), approximately 50% of the compressive strength of regular concrete.",
  "AAC offers incredible opportunities to increase building quality and at the same time reduce costs at the construction site.",
  "AAC is produced out of a mix of quartz sand and/or pulverized fly ash (PFA), lime, cement, gypsum, water and aluminum and is hardened by steam-curing in autoclaves. As a result of its excellent properties, AAC is used in many building constructions, for example in residential homes, commercial and industrial buildings, schools, hospitals, hotels and many other applications. AAC contains 60% to 85% air by volume.",
];

export const GALLERY = [
  "/images/img1large.jpg",
  "/images/img12large.jpg",
  // "/images/img_11.jpg",
  "/images/img17large.jpg",
  "/images/img1large.jpg",
  "/images/img12large.jpg",
  "/images/img17large.jpg",
  // "/images/img_11.jpg",
  "/images/img1large.jpg",
  "/images/img17large.jpg",
  "/images/img12large.jpg",
  // "/images/img_11.jpg",
];
