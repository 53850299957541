import { Box, Container } from "@mui/material";

function WebSection({ children, bgcolor, isMobile }) {
  return (
    <Box
      sx={{
        width: "100%",
        // backgroundImage: backgroundImage ? backgroundImage : backgroundImage,
        background: bgcolor,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {isMobile ? (
        <Box sx={{ width: "95%" }}>{children}</Box>
      ) : (
        <Container>{children}</Container>
      )}
    </Box>
  );
}

export default WebSection;
