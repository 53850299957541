import { Button, Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { NAVBAR_DATA } from "../../contents/content";
import { useNavigate } from "react-router-dom";
import WebSection from "../../hoc/webSection";
import {
  Call,
  Email,
  Facebook,
  Instagram,
  LocationOn,
} from "@mui/icons-material";

function MobFooter({ activeTab }) {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <WebSection bgcolor={"#000"}>
      <Box
        container
        // spacing={10}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",

          alignItems: "center",
          padding: "2rem 0rem",
        }}
      >
        <img
          style={{ width: 100, objectFit: "contain" }}
          src="/images/infinity-logo.jpeg"
          alt="infinity-block-logo"
        />
        <Grid
          item
          md={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent="center"
          px={0}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: "1.4rem",
              mt: 1,
              fontWeight: 800,
              textAlign: "center",

              // marginLeft: "25px",
            }}
          >
            Infinity Block Industries Pvt.Ltd
          </Typography>
          <Box
            // width={"70%"}
            mt={0.5}
            display={"flex"}
            gap={2}
            justifyContent="center"
          >
            <Typography
              sx={{
                color: "#fff",
                // marginLeft: "25px",
                textAlign: "center",
                fontSize: "1rem",
              }}
            >
              Specializing in the development, manufacture and export of
              eco-friendly, energy-efficient new building materials ---
              autoclaved aerated concrete (autoclaved lightweight concrete)
              Blocks.
            </Typography>
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              sx={{
                width: 230,
                fontFamily: "Kanit",
                color: "white",
                fontSize: 15,
                fontWeight: 500,
                marginTop: 2,
              }}
              href="https://storage.googleapis.com/infinity-block-website.appspot.com/InfinityBlock.pdf"
              target="_blank"
              // }}
              variant="contained"
            >
              Download Catalogue
            </Button>
          </Box>

          <Box
            fullWidth
            my={2}
            mt={4}
            sx={{ background: theme.palette.primary.main, height: "1px" }}
          ></Box>
        </Grid>

        <Box
          width="100%"
          display="flex"
          mt={2}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box
            sx={{
              width: "100%",
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              style={{
                color: theme.palette.primary.main,
                fontWeight: 800,
                // marginLeft: 25,
                textAlign: "center",
                fontSize: "1.1rem",
              }}
            >
              CONTACT INFO
            </Typography>
            <Box
              sx={{
                width: "100px",
                // marginLeft: "25px",
                mt: 1,
                background: theme.palette.primary.main,
                height: "1px",
              }}
            ></Box>
          </Box>
          <Typography
            mt={1}
            style={{
              color: "#fff",
              fontWeight: 800,
              fontSize: "1.1rem",
              textAlign: "center",
              // marginLeft: 25,
            }}
          >
            Infinity Block Industries Pvt.Ltd
          </Typography>
          <Box display={"flex"} mt={1}>
            <LocationOn sx={{ color: theme.palette.primary.main }} />
            <Typography sx={{ color: "#fff", fontSize: "1rem" }}>
              461a,462p Kheda sihunj road, <br></br> Near samspur cross road,
              <br />
              Mahemdavad,<br></br> Gujarat 387130, India
            </Typography>
          </Box>{" "}
          {/* <Box display={"flex"} sx={{ marginLeft: "-110px" }} mt={1}>
            <Call sx={{ color: theme.palette.primary.main }} />
            <Typography sx={{ color: "#fff", fontSize: "1rem" }}>
              +91-8511117481
            </Typography>
          </Box> */}
          <Box display={"flex"} mt={1}>
            <Email sx={{ color: theme.palette.primary.main }} />
            <Typography
              sx={{ color: "#fff", fontSize: "1rem", marginLeft: 0.5 }}
            >
              infinityblockspvt@gmail.com
            </Typography>
          </Box>
          <Box
            width={"100%"}
            mt={2}
            display={"flex"}
            justifyContent="center"
            sx={
              {
                // marginLeft: "25px",
              }
            }
            gap={2}
            // justifyContent="center"
          >
            <Instagram
              onClick={() => {
                window.open(
                  "https://instagram.com/infinity_block_industries",
                  "_blank",
                  "noreferrer"
                );
              }}
              sx={{
                color: theme.palette.primary.main,
                fontSize: 30,
                cursor: "pointer",
              }}
            />
            <Facebook
              onClick={() => {
                window.open(
                  "https://www.facebook.com/infinity.block.industries/",
                  "_blank",
                  "noreferrer"
                );
              }}
              sx={{
                color: theme.palette.primary.main,
                fontSize: 30,
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        fullWidth
        sx={{ background: theme.palette.primary.main, height: "1px" }}
      ></Box>
      <Box
        width={"100%"}
        display="flex"
        // color={theme.palette.primary.main}
        flexDirection={"column"}
        alignItems="center"
        py={1}
      >
        <Typography sx={{ fontSize: 16, color: "#fff", textAlign: "center" }}>
          © 2023 Infinity Block Industries Pvt Ltd. <br />
          All rights are reserved.
        </Typography>
      </Box>
    </WebSection>
  );
}

export default MobFooter;
