import React, { useState, useEffect, Suspense } from "react";
import logo from "./logo.svg";

import "./App.css";
import WebsiteView from "./responsiveViews/web/webHomePage";
import MobileView from "./responsiveViews/mobile/mobilHomePage";
import {
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import theme from "./theme/materialTheme";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import HomePage from "./pages/home";
import AOS from "aos";
import { storage } from "./utils/firebase";
import "aos/dist/aos.css";
import ScrollToTop from "./hoc/scrollToTop";
import NavBar from "./components/navbar";
import MainLoader from "./components/mainLoader";
import { LoadingProvider } from "./context/loadingContext";

const AboutUsPage = React.lazy(() => import("./pages/aboutUs"));
const AACBlockPage = React.lazy(() => import("./pages/aacBlock"));
const CompanyInfo = React.lazy(() => import("./pages/companyInfo"));
const ContactInfo = React.lazy(() => import("./pages/contactInfo"));
const AACGalleryPage = React.lazy(() => import("./pages/aacGallary"));
const AACContactUsPage = React.lazy(() => import("./pages/aacContactUs"));
const AACBenefitsPage = React.lazy(() => import("./pages/aacBenefits"));
const AACGuidelinePage = React.lazy(() => import("./pages/aacGuidelines"));
const AACTechnicalPage = React.lazy(() => import("./pages/aacTechnical"));
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function App() {
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = 7; // Replace with the total number of images

  useEffect(() => {
    if (
      window.location.pathname !== "/contact-info" &&
      window.location.pathname !== "/company-info"
    ) {
      const imageLoader1 = new Image();
      imageLoader1.src = "/images/bannerBG1.png";
      imageLoader1.onload = handleImageLoad;

      const imageLoader2 = new Image();
      imageLoader2.src = "/images/bannerBG2.png";
      imageLoader2.onload = handleImageLoad;

      const imageLoader3 = new Image();
      imageLoader3.src = "/images/bannerBG3.png";
      imageLoader3.onload = handleImageLoad;

      const imageLoader4 = new Image();
      imageLoader4.src = "/images/bannerText1.png";
      imageLoader4.onload = handleImageLoad;

      const imageLoader5 = new Image();
      imageLoader5.src = "/images/bannerText2.png";
      imageLoader5.onload = handleImageLoad;

      const imageLoader6 = new Image();
      imageLoader6.src = "/images/bannerText3.png";
      imageLoader6.onload = handleImageLoad;

      const imageLoader7 = new Image();
      imageLoader7.src = "/images/infinityLogoBlack.png";
      imageLoader7.onload = handleImageLoad;
    }
  }, []);

  //   useEffect(()=> {
  //  if (
  //       window.location.pathname !== "/contact-info" &&
  //       window.location.pathname !== "/company-info"
  //     ) {
  //     }else{
  //           setLoading(true);
  //     }
  //   },[])
  useEffect(() => {
    // console.log(imagesLoaded, " ", totalImages);

    if (
      window.location.pathname !== "/contact-info" &&
      window.location.pathname !== "/company-info"
    ) {
      if (imagesLoaded === totalImages) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [imagesLoaded, totalImages]);
  const handleImageLoad = () => {
    // console.log("call Handle Method");
    setImagesLoaded(
      prevImagesLoaded => (
        console.log("prevImagesLoaded ", prevImagesLoaded), prevImagesLoaded + 1
      )
    );
  };
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    if (windowSize.width <= 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });
  if (loading) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          background: "#232323",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="Tagmate"
            src={"/images/roundLogo.png"}
            height={110}
            width={110}
          />
          <CircularProgress
            size={150}
            // color="primary"
            disableShrink
            sx={{
              color: "#d1ac64",
              position: "absolute",
            }}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        {/* <LoadingContext> */}
        <CssBaseline />
        <LoadingProvider>
          <Router>
            {/* <MainLoader /> */}
            {/* <ScrollToTop /> */}
            {window.location.pathname !== "/contact-info" &&
              window.location.pathname !== "/company-info" && (
                <NavBar isMobile={isMobile} />
              )}

            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <HomePage isMobile={isMobile} windowSize={windowSize} />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/about"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AboutUsPage isMobile={isMobile} windowSize={windowSize} />
                  </Suspense>
                }
              ></Route>

              <Route
                path="/ourProduct"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AACBlockPage isMobile={isMobile} windowSize={windowSize} />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/aac-benefits"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AACBenefitsPage
                      isMobile={isMobile}
                      windowSize={windowSize}
                    />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/aac-guidelines"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AACGuidelinePage
                      isMobile={isMobile}
                      windowSize={windowSize}
                    />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/aac-technical"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AACTechnicalPage
                      isMobile={isMobile}
                      windowSize={windowSize}
                    />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/infinity-gallary"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AACGalleryPage
                      isMobile={isMobile}
                      windowSize={windowSize}
                    />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/contact-us"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AACContactUsPage
                      isMobile={isMobile}
                      windowSize={windowSize}
                    />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/contact-info"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <ContactInfo isMobile={isMobile} windowSize={windowSize} />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/company-info"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <CompanyInfo isMobile={isMobile} windowSize={windowSize} />
                  </Suspense>
                }
              ></Route>

              {/* {isMobile ? <MobileView /> : <WebsiteView windowSize={windowSize} />} */}
            </Routes>
          </Router>
        </LoadingProvider>
        {/* </LoadingContext> */}
      </ThemeProvider>
    );
  }
}

export default App;
