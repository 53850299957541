import {
  ArrowForward,
  Call,
  Check,
  CheckCircle,
  Email,
  Facebook,
  Instagram,
  LocationOn,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { borderColor } from "@mui/system";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WebFooter from "../../components/footer";
import NavBar from "../../components/navbar";
import WebSlider from "../../components/slider";
import {
  ABOUT_US_CONTENT,
  BENEFITS_OF_AAC,
  BENEFITS_TEXT,
  NAVBAR_DATA,
} from "../../contents/content";
import WebSection from "../../hoc/webSection";
import { analytics } from "../../utils/firebase";

const WebContainer = styled(Box)({
  minWidth: 1024,
  border: "1px solid black",
  position: "relative",
});

function WebHomePage() {
  const theme = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    logEvent(analytics, "infinity_home_page", {
      device: "Website",
    });
  }, []);
  const qualityChecks = item => {
    return (
      <Box
        data-aos="fade-right"
        width={160}
        mb={2}
        gap={0.5}
        display="flex"
        alignItems={"center"}
      >
        <CheckCircle sx={{ color: "green", fontSize: 18 }} />
        <Typography variant="caption" sx={{ fontSize: "1rem" }}>
          {item}
        </Typography>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        // justifyContent: "center",
      }}
    >
      {/* <WebContainer> */}
      {/* <NavBar activeTab={0} /> */}
      {/* <Box sx={{ width: "100%", height: "calc(100% - 165px)" }}>
        <img
          alt="header-banner1"
          style={{ width: "100%" }}
          src="https://www.perlcon.com/wp-content/uploads/2021/09/new-perlcon-banner1.jpg"
        />
      </Box> */}

      {/* <AutoImageSlider />
       */}
      <WebSlider />

      {
        // About Us
      }
      <WebSection bgcolor={"#fff"}>
        <Grid
          container
          spacing={10}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "6rem 1rem",
          }}
        >
          <Grid item md={6} px={0}>
            <img
              data-aos="fade-right"
              style={{ width: "100%", height: 500, objectFit: "cover" }}
              src="https://www.perlcon.com/wp-content/uploads/2021/04/about-img.png"
              alt="about-us"
            />
          </Grid>
          <Grid
            item
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent="flex-start"
            px={2}
          >
            <Typography
              data-aos="fade-left"
              variant="h1"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
                fontSize: "2rem",
              }}
            >
              <span style={{ color: "#424242" }}>About</span> Inifity
            </Typography>
            <Box
              data-aos="fade-left"
              width={200}
              height={3}
              mt={1}
              bgcolor={theme.palette.primary.main}
            ></Box>
            <Typography
              variant="caption"
              data-aos="fade-left"
              mt={3}
              mb={1}
              sx={{ fontSize: "1rem" }}
            >
              {ABOUT_US_CONTENT[0]}
            </Typography>
            <Box mt={1} display={"flex"} gap={3}>
              {qualityChecks(ABOUT_US_CONTENT[1][0])}
              {qualityChecks(ABOUT_US_CONTENT[1][1])}
            </Box>
            <Box display={"flex"} gap={3}>
              {qualityChecks(ABOUT_US_CONTENT[1][2])}
              {qualityChecks(ABOUT_US_CONTENT[1][3])}
            </Box>
            <Button
              onClick={() => {
                navigate(NAVBAR_DATA[1].path);
              }}
              // size="large"
              data-aos="fade-right"
              sx={{
                width: 160,
                fontFamily: "Kanit",
                color: "white",
                fontSize: 17,
                fontWeight: 500,
                marginTop: 2,
              }}
              variant="contained"
            >
              Know More
            </Button>
          </Grid>
        </Grid>
      </WebSection>

      <WebSection bgcolor={"#f5f3ec"}>
        <Grid
          container
          spacing={10}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "6rem 1rem",
          }}
        >
          <Grid
            item
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent="flex-start"
            px={2}
          >
            {/* <Typography
              data-aos="fade-right"
              variant="caption"
              sx={{
                color: "#424242",
                fontWeight: 600,
                fontSize: "1.2rem",
              }}
            >
              Our Products
            </Typography> */}
            <Typography
              data-aos="fade-right"
              variant="h1"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
                fontSize: "2rem",
                mt: 1,
              }}
            >
              <span style={{ color: "#424242" }}>Our Product</span> <br></br>{" "}
              Infinity AAC Block <br></br>{" "}
              <span style={{ color: "#424242" }}>(FlyAsh/ SandBase)</span>
            </Typography>
            <Box
              data-aos="fade-right"
              width={300}
              mt={1}
              height={3}
              bgcolor={theme.palette.primary.main}
            ></Box>
            <Typography
              mt={3}
              data-aos="fade-right"
              mb={1}
              variant="caption"
              sx={{ fontSize: "1rem" }}
            >
              The Infinity AAC block is a unique building material composed
              primarily of silica sand/fly ash, cement, lime, and aluminum
              paste. It is a popular cellular concrete molding material used
              worldwide in the construction industry for building panels and
              wall blocks
            </Typography>

            <Button
              data-aos="fade-right"
              onClick={() => {
                navigate(NAVBAR_DATA[2].path);
              }}
              sx={{
                width: 160,
                fontFamily: "Kanit",
                color: "white",
                fontSize: 17,
                fontWeight: 500,
                marginTop: 2,
              }}
              variant="contained"
            >
              View More
            </Button>
          </Grid>
          <Grid item md={6} px={0}>
            <img
              data-aos="fade-left"
              style={{ width: "100%", height: 350, objectFit: "cover" }}
              src="/images/infinityWorker.png"
              alt="Infinity AAC Blocks"
            />
          </Grid>
        </Grid>
      </WebSection>

      {/* Benfits */}

      <WebSection bgcolor={"#fff"}>
        <Box
          width={"100%"}
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          py={10}
        >
          <Typography
            variant="h1"
            data-aos="flip-up"
            sx={{
              fontSize: "2rem",
              color: theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            <span style={{ color: "#424242" }}>Benefits of</span> Inifity AAC
            Block{" "}
          </Typography>
          <Box
            data-aos="flip-up"
            width={250}
            height={3}
            mt={2}
            bgcolor={theme.palette.primary.main}
          ></Box>
          <Typography
            data-aos="flip-up"
            variant="caption"
            mt={3}
            sx={{ fontSize: "1rem" }}
          >
            {BENEFITS_TEXT[0]}
          </Typography>
          <Typography data-aos="flip-up" mb={1} sx={{ fontSize: "1rem" }}>
            {BENEFITS_TEXT[1]}
          </Typography>
          <Grid
            mt={3}
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {BENEFITS_OF_AAC.map((item, index) => {
              if (item.shortDescription) {
                return (
                  <Grid item md={2.3}>
                    <Box
                      data-aos="flip-left"
                      width={"100%"}
                      height={320}
                      display="flex"
                      flexDirection="column"
                      borderRadius={3}
                      alignItems={"center"}
                      p={2}
                      boxShadow={"0px 2px 5px 0px rgba(0,0,0,0.75)"}
                      // bgcolor="#ececec"
                    >
                      {/* <Box
                      width={100}
                      height={100}
                      display="flex"
                      justifyContent={"center"}
                      alignItems={"center"}
                      borderRadius={100}
                      bgcolor={"#fff"}
                    > */}
                      {/* <Box
                      sx={{
                        height: 45,
                        width: 45,
                        background: `${theme.palette.primary.main} url(${item.img})`,
                      }}
                    ></Box> */}
                      <img alt={item.title} src={item.img} />
                      {/* </Box> */}
                      <Box
                        sx={{
                          width: "100",
                          height: 70,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            fontSize: 17,
                            fontWeight: 600,
                            textAlign: "center",
                            marginTop: 2,
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Box>

                      <Box
                        width={"100%"}
                        sx={{ height: "200px", overflow: "auto" }}
                      >
                        <Typography
                          sx={{
                            fontSize: 15,
                            marginTop: 2,
                            textAlign: "center",
                          }}
                        >
                          {item.shortDescription}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              }
            })}
          </Grid>
          <Button
            data-aos="fade-up"
            onClick={() => {
              navigate(NAVBAR_DATA[3].path);
            }}
            // sx={{
            // color: theme.palette.primary.main,
            // background: "#fff",
            // fontWeight: 600,
            sx={{
              width: 230,
              fontFamily: "Kanit",
              color: "white",
              fontSize: 17,
              fontWeight: 500,
              marginTop: 7,
            }}
            // }}
            variant="contained"
          >
            View More Benefits
            <ArrowForward sx={{ marginLeft: 2, fontSize: 16 }} />
          </Button>
        </Box>
      </WebSection>

      <WebSection bgcolor={"#242424"}>
        <Grid
          container
          spacing={10}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "4rem 1rem",
          }}
        >
          <Grid
            item
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent="flex-start"
            px={2}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 600,
                fontSize: "1.1rem",
              }}
            >
              Leave A Message
            </Typography>
            <Typography
              variant="h1"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
                fontSize: "2rem",
              }}
            >
              Contact Us Now
            </Typography>
            <Box
              width={200}
              height={3}
              bgcolor={theme.palette.primary.main}
            ></Box>
            <Typography mt={3} mb={2} sx={{ fontSize: "1rem", color: "#fff" }}>
              If you have any questions, please get in touch with us right away.
              We are ready to assist 24/7. We welcome your cooperation and we
              will develop with you.
            </Typography>
            {[
              "Factory-direct Connectivity Solutions.",
              "High quality, Competitive Price.",
              "Custom-tailored solutions.",
              "Problem-solving approach.",
            ].map((item, index) => {
              return (
                <Box
                  // width={160}
                  mb={1}
                  key={index}
                  gap={0.5}
                  display="flex"
                  alignItems={"center"}
                >
                  <CheckCircle
                    sx={{ color: theme.palette.primary.main, fontSize: 18 }}
                  />
                  <Typography sx={{ fontSize: "1rem", color: "#fff" }}>
                    {" "}
                    {item}
                  </Typography>
                </Box>
              );
            })}
          </Grid>
          <Grid item md={6} px={0}>
            <form>
              <TextField
                fullWidth
                name="fname"
                autoComplete={"none"}
                margin="dense"
                placeholder="Full Name"
                sx={{
                  "&.MuiTextField-root": {
                    border: `1px solid ${"#fff"}`,
                    borderRadius: "5px",
                    outline: "none",
                  },
                  "& input": {
                    color: "#fff",
                    fontSize: 18,
                  },
                }}
                // label="Full Name"
                type={"text"}
              />
              <TextField
                fullWidth
                margin="dense"
                autoComplete={"none"}
                name="Iemail"
                sx={{
                  "&.MuiTextField-root": {
                    border: `1px solid ${"#fff"}`,
                    borderRadius: "5px",
                    color: "#fff",
                    outline: "none",
                  },
                  "& input": {
                    color: "#fff",
                    fontSize: 18,
                  },
                }}
                placeholder="Email"
                // label="Email"
                type={"text"}
              />
              <TextField
                fullWidth
                margin="dense"
                autoComplete={"none"}
                name="IphoneNum"
                // label="Mobile Number"
                sx={{
                  "&.MuiTextField-root": {
                    border: `1px solid ${"#fff"}`,
                    borderRadius: "5px",
                    outline: "none",
                    color: "#fff",
                  },
                  "& input": {
                    color: "#fff",
                    fontSize: 18,
                  },
                }}
                placeholder="Mobile Number"
                type={"text"}
              />
              <TextField
                fullWidth
                name="Imessage"
                // label="Message"
                autoComplete={"none"}
                color="primary"
                rows={3}
                margin="dense"
                multiline
                sx={{
                  "&.MuiTextField-root": {
                    border: `1px solid ${"#fff"}`,
                    borderRadius: "5px",
                    color: "#fff",
                    outline: "none",
                  },
                  "& textarea": {
                    color: "#fff",
                    fontSize: 18,
                  },
                }}
                placeholder="Message"
                type={"text"}
              />
              <Button
                sx={{
                  width: 140,
                  fontFamily: "Kanit",
                  color: "white",
                  fontSize: 17,
                  fontWeight: 500,
                  marginTop: 2,
                }}
                variant="contained"
              >
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </WebSection>
      <WebFooter />
      {/* <WebSection bgcolor={theme.palette.primary.main}>
       
      </WebSection> */}
    </Box>
  );
}

export default WebHomePage;
