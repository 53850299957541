import { useEffect } from "react";
import MobileHomePage from "../../responsiveViews/mobile/mobilHomePage";
import WebHomePage from "../../responsiveViews/web/webHomePage";
import { Helmet } from "react-helmet";

function HomePage({ isMobile, windowSize }) {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Home | Infinity Block</title>
        <meta
          name="description"
          content="Infinity Block Pvt. Ltd. is dedicated to creating a sustainable society where the environment and future can coexist in harmony. Contemporary construction practices encompass various essential aspects."
        />
      </Helmet>
      {isMobile ? <MobileHomePage /> : <WebHomePage windowSize={windowSize} />}
    </div>
  );
}

export default HomePage;
