import {
  ArrowForward,
  Call,
  Check,
  CheckCircle,
  Email,
  Facebook,
  Instagram,
  LocationOn,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { borderColor } from "@mui/system";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WebFooter from "../../components/footer";
import MobFooter from "../../components/footer/mobileFooter";
import NavBar from "../../components/navbar";
import MobNavBar from "../../components/navbar/mobileNavbar";
import WebSlider from "../../components/slider";
import {
  ABOUT_US_CONTENT,
  BENEFITS_OF_AAC,
  BENEFITS_TEXT,
  NAVBAR_DATA,
} from "../../contents/content";
import WebSection from "../../hoc/webSection";
import { analytics } from "../../utils/firebase";

const WebContainer = styled(Box)({
  minWidth: 1024,
  border: "1px solid black",
  position: "relative",
});

function MobileHomePage() {
  const theme = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    logEvent(analytics, "infinity_home_page", {
      device: "Mobile",
    });
  }, []);
  const qualityChecks = item => {
    return (
      <Box
        data-aos="fade-up"
        data-aos-offset="0"
        width={"50%"}
        mb={2}
        gap={0.5}
        display="flex"
        alignItems={"center"}
      >
        <CheckCircle sx={{ color: "green", fontSize: 18 }} />
        <Typography sx={{ fontSize: "1rem" }}>{item}</Typography>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        marginTop: "135px",
        // width: "100vw",
        // justifyContent: "center",
      }}
    >
      {/* <WebContainer> */}
      {/* <MobNavBar activeTab={0} /> */}
      {/* <Box sx={{ width: "100%", height: "calc(100% - 110px)" }}>
        <img
          alt="header-banner1"
          style={{ width: "100%" }}
          src="https://www.perlcon.com/wp-content/uploads/2021/09/new-perlcon-banner1.jpg"
        />
      </Box> */}
      <WebSlider isMobile={true} />

      {
        // About Us
      }
      <WebSection isMobile={true} bgcolor={"#fff"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems="flex-start"
          px={2}
          py={4}
        >
          <Typography
            data-aos="fade-up"
            data-aos-offset="0"
            variant="h1"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 600,
              fontSize: "2rem",
            }}
          >
            <span style={{ color: "#424242" }}>About</span> Inifity
          </Typography>
          <Box
            data-aos="fade-up"
            data-aos-offset="0"
            width={140}
            height={3}
            mt={1}
            bgcolor={theme.palette.primary.main}
          ></Box>
          <Typography
            variant="caption"
            data-aos="fade-up"
            data-aos-offset="0"
            // data-aos-offset="0"
            mt={3}
            mb={1}
            sx={{ fontSize: "1rem", textAlign: "left" }}
          >
            {ABOUT_US_CONTENT[0]}
          </Typography>
          <Box mt={1} width="100%" display={"flex"} flexWrap="wrap">
            {qualityChecks(ABOUT_US_CONTENT[1][0])}
            {qualityChecks(ABOUT_US_CONTENT[1][1])}
            {qualityChecks(ABOUT_US_CONTENT[1][2])}
            {qualityChecks(ABOUT_US_CONTENT[1][3])}
          </Box>
          {/* <Box display={"flex"} gap={3}></Box> */}
          <Button
            onClick={() => {
              navigate(NAVBAR_DATA[1].path);
            }}
            data-aos="fade-up"
            data-aos-offset="0"
            sx={{
              width: 140,
              fontFamily: "Kanit",
              color: "white",

              fontSize: 17,
              fontWeight: 500,
              marginTop: 2,
            }}
            variant="contained"
          >
            Know More
          </Button>
        </Box>
      </WebSection>

      <WebSection isMobile={true} bgcolor={"#f5f3ec"}>
        <Grid
          container
          spacing={10}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "4rem 1rem",
          }}
        >
          <Grid
            item
            md={6}
            display={"flex"}
            flexDirection={"column"}
            justifyContent="flex-start"
            px={2}
          >
            {/* <Typography
              sx={{
                color: "#fff",
                fontWeight: 600,
                fontSize: "1.2rem",
              }}
            >
              Our Products
            </Typography> */}
            <Typography
              data-aos="fade-up"
              variant="h1"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
                fontSize: "1.8rem",
                mt: 1,
              }}
            >
              <span style={{ color: "#424242", fontSize: "1.5rem" }}>
                Our Product
              </span>{" "}
              <br></br> Infinity AAC Block <br></br>{" "}
              <span style={{ color: "#424242" }}>(FlyAsh/ SandBase)</span>
            </Typography>
            <Box
              width={200}
              mt={1}
              data-aos="fade-up"
              data-aos-offset="0"
              height={3}
              bgcolor={theme.palette.primary.main}
            ></Box>
            <Typography
              data-aos="fade-up"
              data-aos-offset="0"
              mt={3}
              mb={1}
              sx={{ fontSize: "1rem" }}
            >
              The Infinity AAC block is a unique building material composed
              primarily of silica sand/fly ash, cement, lime, and aluminum
              paste. It is a popular cellular concrete molding material used
              worldwide in the construction industry for building panels and
              wall blocks
            </Typography>

            <Button
              onClick={() => {
                navigate(NAVBAR_DATA[2].path);
              }}
              data-aos="fade-up"
              data-aos-offset="0"
              sx={{
                width: 140,
                fontFamily: "Kanit",
                color: "white",

                fontSize: 17,
                fontWeight: 500,
                marginTop: 2,
              }}
              variant="contained"
            >
              View More
            </Button>
          </Grid>
          <Grid item md={6} px={0}>
            <img
              style={{ width: "100%", height: 350 }}
              src="/images/infinityWorker.png"
              alt="Infinity AAC Blocks"
            />
          </Grid>
        </Grid>
      </WebSection>

      {/* Benfits */}

      <WebSection isMobile={true} bgcolor={"#fff"}>
        <Box
          width={"100%"}
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          py={6}
        >
          <Typography
            variant="h1"
            data-aos="flip-up"
            sx={{
              fontSize: "1.8rem",
              color: theme.palette.primary.main,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            <span style={{ color: "#424242" }}>Benefits of</span>
            <br></br> Inifity AAC Block{" "}
          </Typography>
          <Box
            width={150}
            height={3}
            mt={2}
            bgcolor={theme.palette.primary.main}
          ></Box>
          <Typography mt={3} textAlign="center" sx={{ fontSize: "1rem" }}>
            {BENEFITS_TEXT[0]}
          </Typography>
          <Typography textAlign="center" mb={1} sx={{ fontSize: "1rem" }}>
            {BENEFITS_TEXT[1]}
          </Typography>
          <Grid
            px={2}
            mt={3}
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {BENEFITS_OF_AAC.slice(0, 6).map((item, index) => {
              if (item.shortDescription) {
                return (
                  <Grid item md={2.3} sm={12} xs={12}>
                    <Box
                      width={"100%"}
                      data-aos="flip-left"
                      height={"auto"}
                      display="flex"
                      flexDirection="column"
                      borderRadius={3}
                      alignItems={"center"}
                      p={2}
                      bgcolor="#ececec"
                    >
                      <Box
                        width={100}
                        height={100}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderRadius={100}
                        bgcolor={"#fff"}
                      >
                        <img alt={item.title} src={item.img} />
                      </Box>

                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: 17,
                          fontWeight: 600,
                          marginTop: 2,
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          marginTop: 2,
                          textAlign: "center",
                        }}
                      >
                        {item.shortDescription}
                      </Typography>
                    </Box>
                  </Grid>
                );
              }
            })}
          </Grid>
          <Button
            onClick={() => {
              navigate(NAVBAR_DATA[3].path);
            }}
            data-aos="fade-up"
            data-aos-offset="0"
            sx={{
              // width: 140,
              fontFamily: "Kanit",
              color: "white",

              fontSize: 17,
              fontWeight: 500,
              marginTop: 5,
            }}
            variant="contained"
          >
            View More Benefits
            <ArrowForward sx={{ marginLeft: 2, fontSize: 16 }} />
          </Button>
        </Box>
      </WebSection>

      <WebSection isMobile={true} bgcolor={"#242424"}>
        <Grid
          container
          spacing={4}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "4rem 1rem",
          }}
        >
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent="flex-start"
            px={2}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 600,
                fontSize: "1.1rem",
              }}
            >
              Leave A Message
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 600,
                fontSize: "2rem",
              }}
            >
              Contact us Now
            </Typography>
            <Box
              width={200}
              height={3}
              bgcolor={theme.palette.primary.main}
            ></Box>
            <Typography mt={3} mb={2} sx={{ fontSize: "1rem", color: "#fff" }}>
              If you have any questions, please get in touch with us right away.
              We are ready to assist 24/7. We welcome your cooperation and we
              will develop with you.
            </Typography>
            {[
              "Factory-direct Connectivity Solutions.",
              "High quality, Competitive Price.",
              "Custom-tailored solutions.",
              "Problem-solving approach.",
            ].map((item, index) => {
              return (
                <Box
                  // width={160}
                  mb={1}
                  key={index}
                  gap={0.5}
                  display="flex"
                  alignItems={"center"}
                >
                  <CheckCircle
                    sx={{ color: theme.palette.primary.main, fontSize: 18 }}
                  />
                  <Typography sx={{ fontSize: "1rem", color: "#fff" }}>
                    {" "}
                    {item}
                  </Typography>
                </Box>
              );
            })}
          </Grid>
          <Grid item md={6} sm={12} xs={12} px={0}>
            <form>
              <TextField
                fullWidth
                name="fname"
                size={"small"}
                autoComplete={"none"}
                margin="dense"
                placeholder="Full Name"
                sx={{
                  "&.MuiTextField-root": {
                    border: `1px solid ${"#fff"}`,
                    borderRadius: "5px",
                    outline: "none",
                  },
                  "& input": {
                    color: "#fff",
                    fontSize: 16,
                  },
                }}
                // label="Full Name"
                type={"text"}
              />
              <TextField
                fullWidth
                size={"small"}
                margin="dense"
                autoComplete={"none"}
                name="Iemail"
                sx={{
                  // mt: 1,
                  "&.MuiTextField-root": {
                    border: `1px solid ${"#fff"}`,
                    borderRadius: "5px",
                    color: "#fff",
                    outline: "none",
                  },
                  "& input": {
                    color: "#fff",
                    fontSize: 16,
                  },
                }}
                placeholder="Email"
                // label="Email"
                type={"text"}
              />
              <TextField
                fullWidth
                size={"small"}
                margin="dense"
                autoComplete={"none"}
                name="IphoneNum"
                // label="Mobile Number"
                sx={{
                  "&.MuiTextField-root": {
                    border: `1px solid ${"#fff"}`,
                    borderRadius: "5px",
                    outline: "none",
                    color: "#fff",
                  },
                  "& input": {
                    color: "#fff",
                    fontSize: 16,
                  },
                }}
                placeholder="Mobile Number"
                type={"text"}
              />
              <TextField
                fullWidth
                name="Imessage"
                size={"small"}
                // label="Message"
                autoComplete={"none"}
                color="primary"
                rows={3}
                margin="dense"
                multiline
                sx={{
                  "&.MuiTextField-root": {
                    border: `1px solid ${"#fff"}`,
                    borderRadius: "5px",
                    color: "#fff",
                    outline: "none",
                  },
                  "& textarea": {
                    color: "#fff",
                    fontSize: 16,
                  },
                }}
                placeholder="Message"
                type={"text"}
              />
              <Button
                fullWidth
                sx={{
                  // width: 140,
                  fontFamily: "Kanit",
                  color: "white",

                  fontSize: 17,
                  fontWeight: 500,
                  marginTop: 3,
                }}
                variant="contained"
              >
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </WebSection>
      <MobFooter />
      {/* <WebSection bgcolor={theme.palette.primary.main}>
       
      </WebSection> */}
    </Box>
  );
}

export default MobileHomePage;
