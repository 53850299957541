import { createTheme } from "@mui/material";
import Poppins from "../fonts/Poppins400.ttf";
import DMSans from "../fonts/DMSans-Regular.ttf";
import Jost from "../fonts/Jost-Regular.ttf";

const theme = createTheme({
  palette: {
    primary: {
      main: "#d1ac64",

      // main: "#b98036",
    },
    custom: {
      backgroundColor: "#000",
      goldenLightBG: "#ffe3bf",
    },
  },
  typography: {
    fontFamily: ["DMSans", "Poppins", "Jost", "Kanit", "sans-serif"].join(","),
    h1: {
      // fontSize: 30,
      fontFamily: "Kanit",
      letterSpacing: 1,
      // fontWeight: 400,
    },

    caption: {
      fontFamily: "DMSans",
    },
    subtitle1: {
      fontFamily: "Roboto",
    },
    h2: {
      fontSize: 18,
      fontFamily: "StraitRegular",
    },
    h3: {
      fontSize: 16,
    },
    body1: {
      fontWeight: 400,
      fontSize: 14,
    },
  },
  button: {
    styleOverrides: {
      root: {
        // backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontFamily: "Kanit",
        fontWeight: 500,
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          // "scrollbar-width": "",
        },
        "@font-face": [
          {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `
              local('Poppins'),
              url(${Poppins}) format('truetype')
            `,
          },
          {
            fontFamily: "DMSans",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `
              local('DMSans'),
              url(${DMSans}) format('truetype')
            `,
          },
          {
            fontFamily: "Jost",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `
              local('Jost'),
              url(${Jost}) format('truetype')
            `,
          },
        ],
        "*::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "darkgrey",
          borderRadius: 6,
        },
      },
    },
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "gray",
        },
        color: "white", // if you also want to change the color of the input, this is the prop you'd use
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiListItem: {
      root: {
        height: 54,
        "&$selected": {
          color: "#135CEF",
          backgroundColor: "rgba(229, 238, 255, 1)",
          "&:hover": {
            backgroundColor: "rgba(229, 238, 255, 1)",
          },
        },
      },
      button: {
        "&:hover": {
          color: "#135CEF",
          backgroundColor: "rgba(229, 238, 255, 1)",
        },
      },
    },
    MuiListItemText: {
      root: {
        fontWeight: 500,
        color: "inherit",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0,
        color: "inherit",
      },
    },
  },
});

export default theme;
