import { Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { NAVBAR_DATA } from "../../contents/content";
import { useNavigate } from "react-router-dom";
import { Close, MenuRounded } from "@mui/icons-material";

function NavBar({ activeTab, isMobile }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerStatus] = useState(false);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: isMobile ? 120 : 120,
          // position: "fixed",
          zIndex: 1111,
          position: isMobile ? "fixed" : "unset",
          top: 0,
          display: "flex",
          justifyContent: "center",
          backgroundImage:
            "linear-gradient(to right, #B9A05E , #D1AD65, #F0D383)",

          // backgroundColor: theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            // width: "100%",
            minWidth: isMobile ? "100%" : 1024,
            display: "flex",
            // flexDirection: "column",

            // justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {isMobile && (
              <Box sx={{ position: "absolute", right: 10 }}>
                {isDrawerOpen ? (
                  <Close
                    onClick={() => setDrawerStatus(false)}
                    sx={{ color: "#000", fontSize: "2rem" }}
                  ></Close>
                ) : (
                  <MenuRounded
                    onClick={() => setDrawerStatus(true)}
                    sx={{ color: "#000", fontSize: "2rem" }}
                  ></MenuRounded>
                )}
              </Box>
            )}
            <Box
              data-aos="zoom-in"
              sx={{
                width: isMobile ? 115 : 115,
                height: isMobile ? 115 : 115,
                backgroundColor: "#000",
                borderRadius: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "100%" }}
                src="/images/roundLogo.png"
                alt="infinity-block-logo"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {!isMobile ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: 2,
            alignItems: "center",
            // p: "5px 0px",
            height: 45,

            backgroundColor: "#242424",
            // backgroundImage: "linear-gradient(180deg, "#000, yellow)",
          }}
        >
          {NAVBAR_DATA.map((item, i) => {
            return (
              <Button
                key={i}
                variant="text"
                onClick={() => {
                  navigate(item.path);
                }}
                sx={{
                  // backgroundColor: "#242424",
                  color: activeTab === i ? theme.palette.primary.main : "#fff",
                  fontWeight: 600,
                  "&:hover": {
                    color: theme.palette.primary.main,
                    textDecoration: "underline",
                  },
                }}
              >
                {item.lable}
              </Button>
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: 2,
            alignItems: "center",
            // p: "5px 0px",
            height: 15,
            position: "fixed",
            top: 120,
            zIndex: 1111,
            backgroundColor: "#000",
            // backgroundImage: "linear-gradient(180deg, "#000, yellow)",
          }}
        ></Box>
      )}
      {isDrawerOpen && (
        <Box
          sx={{
            width: "100%",
            height: "auto",
            //   marginTop: "110px",
            // position: "fixed",
            // top: "110px",
            display: "flex",
            top: 135,
            position: "fixed",
            zIndex: 1111,
            flexDirection: "column",
            // position: "absolute",
            padding: "1rem 2rem",
            alignItems: "center",

            cursor: "pointer",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          }}
        >
          {NAVBAR_DATA.map((item, i) => {
            return (
              <Typography
                my={1}
                onClick={() => {
                  setDrawerStatus(false);
                  navigate(item.path);
                }}
                sx={{
                  // backgroundColor: "#000",
                  fontSize: "1.2rem",
                  color: activeTab === i ? theme.palette.primary.main : "#fff",
                  fontWeight: 600,
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {item.lable}
              </Typography>
            );
          })}
          <Button
            sx={{
              width: 230,
              fontFamily: "Kanit",
              color: "white",
              fontSize: 15,
              fontWeight: 500,
              marginTop: 2,
            }}
            href="https://storage.googleapis.com/infinity-block-website.appspot.com/InfinityBlock.pdf"
            target="_blank"
            // }}
            variant="contained"
          >
            Download Catalogue
          </Button>
        </Box>
      )}
    </>
  );
}

export default NavBar;
