import { Button, Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { NAVBAR_DATA } from "../../contents/content";
import { useNavigate } from "react-router-dom";
import WebSection from "../../hoc/webSection";
import {
  Call,
  Email,
  Facebook,
  Instagram,
  LocationOn,
} from "@mui/icons-material";

function WebFooter({ activeTab }) {
  const theme = useTheme();

  const navigate = useNavigate();
  return (
    <WebSection bgcolor={"#000"}>
      <Grid
        container
        // spacing={10}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "4rem 1rem",
        }}
      >
        <Grid
          item
          md={4}
          display={"flex"}
          flexDirection={"column"}
          justifyContent="flex-start"
          px={0}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: "1.6rem",
              fontWeight: 800,
            }}
          >
            Infinity Block Industries Pvt.Ltd
          </Typography>
          <Box
            // width={"70%"}
            mt={2}
            display={"flex"}
            gap={2}
            justifyContent="center"
          >
            <Typography sx={{ color: "#fff", fontSize: "1rem" }}>
              Specializing in the development, manufacture and export of
              eco-friendly, energy-efficient new building materials ---
              autoclaved aerated concrete (autoclaved lightweight concrete)
              Blocks.
            </Typography>
          </Box>
          <Button
            sx={{
              width: 230,
              fontFamily: "Kanit",
              color: "white",
              fontSize: 15,
              fontWeight: 500,
              marginTop: 2,
            }}
            href="https://storage.googleapis.com/infinity-block-website.appspot.com/InfinityBlock.pdf"
            target="_blank"
            // }}
            variant="contained"
          >
            Download Catalogue
          </Button>
          <Box
            width={"70%"}
            mt={2}
            display={"flex"}
            gap={2}
            // justifyContent="center"
          >
            <Instagram
              onClick={() => {
                window.open(
                  "https://instagram.com/infinity_block_industries",
                  "_blank",
                  "noreferrer"
                );
              }}
              sx={{
                color: theme.palette.primary.main,
                fontSize: 30,
                cursor: "pointer",
              }}
            />
            <Facebook
              onClick={() => {
                window.open(
                  "https://www.facebook.com/infinity.block.industries/",
                  "_blank",
                  "noreferrer"
                );
              }}
              sx={{
                color: theme.palette.primary.main,
                fontSize: 30,
                cursor: "pointer",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          display="flex"
          flexDirection={"column"}
          alignItems="center"
          px={0}
        >
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontWeight: 800,
              fontSize: "1.1rem",
            }}
          >
            QUICK LINKS
          </Typography>

          <Box mt={2}>
            {NAVBAR_DATA.map((item, i) => {
              return (
                <Typography
                  onClick={() => {
                    navigate(item.path);
                  }}
                  my={0.5}
                  sx={{
                    color: "#fff",
                    fontSize: "1rem",
                    cursor: "pointer",
                    "&:hover": {
                      color: theme.palette.primary.main,
                      textDecoration: "underline",
                    },
                  }}
                >
                  {item.lable}
                </Typography>
              );
            })}
          </Box>
        </Grid>
        <Grid
          item
          md={5}
          px={0}
          display="flex"
          flexDirection={"column"}
          alignItems={"flex-end"}
        >
          <Box>
            <Typography
              style={{
                color: theme.palette.primary.main,
                fontWeight: 800,
                marginLeft: 25,
                fontSize: "1.1rem",
              }}
            >
              CONTACT INFO
            </Typography>
            <Typography
              mt={2}
              style={{
                color: "#fff",
                fontWeight: 800,
                fontSize: "1.1rem",
                marginLeft: 25,
              }}
            >
              Infinity Block Industries Pvt.Ltd
            </Typography>
            <Box display={"flex"} mt={1}>
              <LocationOn sx={{ color: theme.palette.primary.main }} />
              <Typography sx={{ color: "#fff", fontSize: "1rem" }}>
                461a,462p Kheda sihunj road, <br></br> Near samspur cross road,
                <br />
                Mahemdavad,<br></br> Gujarat 387130, India
              </Typography>
            </Box>
            {/* <Box display={"flex"} mt={1}>
              <Call sx={{ color: theme.palette.primary.main }} />
              <Typography sx={{ color: "#fff", fontSize: "1rem" }}>
                +91-8511117481
              </Typography>
            </Box> */}
            <Box display={"flex"} mt={1}>
              <Email sx={{ color: theme.palette.primary.main }} />
              <Typography
                sx={{ color: "#fff", fontSize: "1rem", marginLeft: 0.5 }}
              >
                infinityblockspvt@gmail.com
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        fullWidth
        sx={{ background: theme.palette.primary.main, height: "1px" }}
      ></Box>
      <Box
        width={"100%"}
        display="flex"
        // color={theme.palette.primary.main}
        flexDirection={"column"}
        alignItems="center"
        py={1}
      >
        <Typography sx={{ fontSize: 18, color: "#fff" }}>
          © 2023 Infinity Block Industries Pvt Ltd. All rights are reserved.
        </Typography>
      </Box>
    </WebSection>
  );
}

export default WebFooter;
